<template>
  <div>
    <div class="card">
      <h6 class="card-header" v-if="!toggle">
        {{topic.code}}
        <span v-if="method === 'edit'">
          <fa
            icon="pencil-alt"
            class="text-warning mx-2 pointer"
            @click="toggle = !toggle">
          </fa>
          <fa
            icon="arrow-down"
            class="text-primary pointer mx-2 float-right"
            @click="moveTopic(topic.id, 1)">
          </fa>
          <fa
            icon="arrow-up"
            class="text-primary pointer mx-2 float-right"
            @click="moveTopic(topic.id, -1)">
          </fa>
        </span>
      </h6>
      <div class="card-header" v-if="toggle">
        <h6 class="card-title">
          ข้อกำหนด
          <fa
            icon="pencil-alt"
            class="text-warning mx-2 pointer"
            @click="toggle = !toggle">
          </fa>
        </h6>

        <div class="form-row">
          <sgv-input-text
            label="ข้อกำหนด"
            v-model="formData.code"
            class="col-12"
            :validations="[
              {text: 'required!', value: !$v.formData.code.required && $v.formData.$dirty}
            ]">
          </sgv-input-text>
        </div>

        <button
          type="button"
          class="btn btn-warning"
          @click="updateTopic(topic.id)">
          แก้ไข
        </button>

        <button
          type="button"
          class="btn btn-danger float-right"
          @click="destroyTopic(topic.id)">
          ลบ
        </button>
      </div>

      <DetailTopicDetail
        class="card-body"
        v-for="detail in topic.details"
        :key="detail.id"
        :topicId="topic.id"
        :detail="detail"
        :method="method"
        :paperType="paperType"
        :templateType="templateType"
        @updated="emitUpdated">
      </DetailTopicDetail>

      <button
        v-if="method === 'edit'"
        type="button"
        class="btn btn-outline-success mb-3 mx-3"
        @click="createDetail">
        เพิ่มรายการ
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  UPDATE_TOPIC,
  DESTROY_TOPIC,
  MOVE_TOPIC,
  CREATE_TOPIC_DETAIL,
} from './graph'
import DetailTopicDetail from './DetailTopicDetail'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    topic: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        code: ''
      }
    }
  },
  validations: {
    formData: {
      code: {required}
    }
  },
  methods: {
    updateTopic (id) {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id,
          input: this.formData
        }
      })
      .then(() => {
        this.$v.formData.$reset()
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
        this.toggle = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyTopic (id) {
      this.$apollo.mutate({
        mutation: DESTROY_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    moveTopic (id, step) {
      this.$apollo.mutate({
        mutation: MOVE_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id,
          step
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    createDetail () {
      this.$apollo.mutate({
        mutation: CREATE_TOPIC_DETAIL(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: this.topic.id,
          input: {content: 'คำถาม...'}
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    topic: {
      handler (v) {
        this.formData.code = v.code
      },
      immediate: true
    }
  },
  components: {
    DetailTopicDetail
  }
}
</script>

<style lang="css">

</style>
