var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-4 col-sm-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รหัส"},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('sgv-input-text',{staticClass:"col-6 col-sm-8",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.v.formData.name.$dirty && _vm.v.formData.name.$error}
      ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"วันที่ตรวจ","validations":[
        {text: 'required!', value: _vm.v.formData.appointment.$dirty && _vm.v.formData.appointment.$error}
      ]},model:{value:(_vm.formData.appointment),callback:function ($$v) {_vm.$set(_vm.formData, "appointment", $$v)},expression:"formData.appointment"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ผู้ถูกตรวจ/หน่วยงาน","validations":[
        {text: 'required!', value: _vm.v.formData.auditee.$dirty && _vm.v.formData.auditee.$error}
      ]},model:{value:(_vm.formData.auditee),callback:function ($$v) {_vm.$set(_vm.formData, "auditee", $$v)},expression:"formData.auditee"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ทีมผู้ตรวจ","validations":[
        {text: 'required!', value: _vm.v.formData.auditor.$dirty && _vm.v.formData.auditor.$error}
      ]},model:{value:(_vm.formData.auditor),callback:function ($$v) {_vm.$set(_vm.formData, "auditor", $$v)},expression:"formData.auditor"}})],1),(_vm.paperId > 0)?_c('div',{staticClass:"form-row"},[_c('DetailFormUser',{staticClass:"col-6",attrs:{"title":"จัดทำ/ทบทวน","value":_vm.getUser('reviewed'),"isSubmit":!_vm.formData.approvedAt && !_vm.formData.reviewedAt && _vm.$auth.hasRole(("paper:" + _vm.paperType + ":review")),"isUndo":!_vm.formData.approvedAt && _vm.formData.reviewedAt && _vm.$auth.hasRole(("paper:" + _vm.paperType + ":review")),"submitFunc":_vm.reviewSubmit,"undoFunc":_vm.reviewUndo},on:{"updated":function($event){return _vm.$emit('updated', null)}}}),_c('DetailFormUser',{staticClass:"col-6",attrs:{"title":"อนุมัติ","value":_vm.getUser('approved'),"isSubmit":_vm.formData.reviewedAt && !_vm.formData.approvedAt && _vm.$auth.hasRole(("paper:" + _vm.paperType + ":approve")),"isUndo":_vm.formData.reviewedAt && _vm.formData.approvedAt && _vm.$auth.hasRole(("paper:" + _vm.paperType + ":approve")),"submitFunc":_vm.approveSubmit,"undoFunc":_vm.approveUndo},on:{"updated":function($event){return _vm.$emit('updated', null)}}})],1):_vm._e(),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ใช้งาน","inline":""},model:{value:(_vm.formData.isActive),callback:function ($$v) {_vm.$set(_vm.formData, "isActive", $$v)},expression:"formData.isActive"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }