<template>
  <div>
    <div
      v-if="!toggle"
      class="">
      <h6 class="card-title">
        <span class="text-secondary">{{detail.code}}</span>
        <span v-if="method === 'edit'">
          <fa
            icon="pencil-alt"
            class="text-warning ml-2 pointer"
            @click="toggle = !toggle">
          </fa>
          <fa
            icon="arrow-down"
            class="text-primary pointer mx-2 float-right"
            @click="moveDetail(detail.id, 1)">
          </fa>
          <fa
            icon="arrow-up"
            class="text-primary pointer mx-2 float-right"
            @click="moveDetail(detail.id, -1)">
          </fa>
        </span>
      </h6>
      <div class="card-text">
        <div class="row">
          <dd class="col-4 col-sm-3 text-right text-warning">คำถาม:</dd>
          <dd class="col-8 col-sm-9 pre-line">{{detail.content}}</dd>

          <dd class="col-4 col-sm-3 text-right text-success">ผล:</dd>
          <dd class="col-8 col-sm-9">
            {{getCheckListPoint(detail.checkListPoint)}}
          </dd>

          <dd class="col-4 col-sm-3 text-right text-info">หลักฐาน/บันทึก:</dd>
          <dd class="col-8 col-sm-9 pre-line">{{detail.remark}}</dd>
        </div>
      </div>
    </div>

    <div
      class="row mb-4"
      v-if="toggle">
      <div class="col-12">
        <h6 class="card-title">
          ข้อกำหนด
          <fa
            icon="pencil-alt"
            class="text-warning ml-1 pointer"
            @click="toggle = !toggle">
          </fa>
        </h6>
      </div>

      <div class="col-12">
        <div class="form-row">
          <sgv-input-textarea
            label="คำถาม"
            v-model="formData.content"
            class="col-12"
            :rows="4"
            :validations="[
              {text: 'required!', value: !$v.formData.content.required && $v.formData.$dirty}
            ]">
          </sgv-input-textarea>

          <sgv-input-radio
            label="ผล"
            class="col-12"
            :options="checkListPointOptions"
            select="value"
            inline
            v-model="formData.checkListPoint"
            :validations="[
              {text: 'required!', value: !$v.formData.checkListPoint.required && $v.formData.$dirty},
              {text: 'จำนวนไม่อยู่ในช่วง', value: !$v.formData.checkListPoint.between && $v.formData.$dirty}
            ]">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-radio>

          <sgv-input-textarea
            label="หลักฐาน/บันทึกเพิ่มเติม"
            :rows="4"
            v-model="formData.remark"
            class="col-12">
          </sgv-input-textarea>
        </div>

        <button
          type="button"
          class="btn btn-warning"
          @click="updateDetail(detail.id)">
          แก้ไข
        </button>

        <button
          type="button"
          class="btn btn-danger float-right"
          @click="destroyDetail(detail.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  UPDATE_TOPIC_DETAIL,
  DESTROY_TOPIC_DETAIL,
  MOVE_TOPIC_DETAIL
} from './graph'

export default {
  props: {
    detail: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      checkListPointOptions: [
        {text: '-', value: 0},
        {text: '(C) เป็นไปตามข้อกำหนด', value: 1},
        {text: '(NC) ความบกพร่อง', value: -1},
        {text: '(O) ข้อสังเกต', value: 2},
      ],
      formData: {
        content: '',
        checkListPoint: 0,
        remark: ''
      }
    }
  },
  validations: {
    formData: {
      content: {required},
      checkListPoint: {
        required,
        between: function(point) {
          return point >= -1 && point <= 2
        }
      },
    }
  },
  methods: {
    getCheckListPoint (value) {
      return this.checkListPointOptions.find(v => v.value === value).text
    },
    updateDetail (id) {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_TOPIC_DETAIL(this.templateType),
        variables: {
          paperType: this.paperType,
          detailId: id,
          input: this.formData
        }
      })
      .then(() => {
        this.$v.formData.$reset()
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
        this.toggle = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyDetail (id) {
      this.$apollo.mutate({
        mutation: DESTROY_TOPIC_DETAIL(this.templateType),
        variables: {
          paperType: this.paperType,
          detailId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    moveDetail (id, step) {
      this.$apollo.mutate({
        mutation: MOVE_TOPIC_DETAIL(this.templateType),
        variables: {
          paperType: this.paperType,
          detailId: id,
          step
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    'detail': {
      handler (v) {
        this.formData.content = v.content
        this.formData.checkListPoint = v.checkListPoint
        this.formData.remark = v.remark
      },
      immediate: true
    }
  }
}
</script>

<style lang="css">

</style>
